import React from 'react'
import FullscreenLandingSection from '../components/FullscreenLandingSection'
import NavigationLink from '../components/NavigationLink'

const Page = () => {
  return (
    <React.Fragment>
      <FullscreenLandingSection imageRelativePath={'Titelbild_404.jpg'} bgImageMask="mask-black" menuInverted
                                logoInverted>
        <div className="row">
          <div className="col">
            <div className="text-center text-white">
              <p className="intro">HTTP/404 - Not Found</p>
              <h1 className="headline-super">Huch, hier ist etwas schief gelaufen.</h1>
              <p className="intro mb-5">Der gesuchte Inhalt scheint nicht mehr verfügbar zu sein.<br/> Bitte
                besuchen Sie unsere Startseite.</p>
              <p><NavigationLink to="/" ariaLabel="Startseite der Webseite der BUSCHMAIS GbR"
                                 asActionButton buttonClassName="btn-white">www.buschmais.de</NavigationLink></p>
            </div>
          </div>
        </div>
      </FullscreenLandingSection>
    </React.Fragment>
  )
}

export default Page

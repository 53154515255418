import * as PropTypes from 'prop-types'
import React from 'react'

const ScrollIndicator = ({ scrollAnchor }) => (
  <footer className="section-footer scrolldown">
    <a className="down" href={'#' + scrollAnchor}>
      <span className="icon"/>
      <span className="txt">Scroll</span>
    </a>
  </footer>
)

export default ScrollIndicator

ScrollIndicator.propTypes = {
  scrollAnchor: PropTypes.string,
}

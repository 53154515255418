import * as PropTypes from 'prop-types'
import React from 'react'
import ScrollIndicator from './ScrollIndicator'
import Section from './Section'
import SectionWrapper from './SectionWrapper'
import SectionContent from './SectionContent'
import classNames from 'classnames'
import SectionCover from './SectionCover'
import BackgroundAwareness from './BackgroundAwareness'

const FullscreenLandingSection = ({
                                    scrollIndicatorAnchor,
                                    imageRelativePath,
                                    logoInverted = false,
                                    menuInverted = false,
                                    className,
                                    backgroundColor,
                                    bgImageMask,
                                    children,
                                  }) => (
  <BackgroundAwareness menuInverted={menuInverted} logoInverted={logoInverted}>
    <Section className={classNames('section-home fullscreen-md main-home section-center', className)}>
      {imageRelativePath &&
      <SectionCover className={bgImageMask} imageRelativePath={imageRelativePath} backgroundColor={backgroundColor}/>
      }
      <SectionWrapper className="with-margin">
        <SectionContent className="mt-0 mt-md-5 mt-lg-0">{children}</SectionContent>
        {scrollIndicatorAnchor &&
        <ScrollIndicator scrollAnchor={scrollIndicatorAnchor}/>
        }
      </SectionWrapper>
    </Section>
  </BackgroundAwareness>
)

export default FullscreenLandingSection

FullscreenLandingSection.propTypes = {
  backgroundColor: PropTypes.string,
  bgImageMask: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  imageRelativePath: PropTypes.string,
  logoInverted: PropTypes.bool,
  menuInverted: PropTypes.bool,
  scrollIndicatorAnchor: PropTypes.string,
}

FullscreenLandingSection.defaultProps = {
  logoInverted: false,
  menuInverted: false,
}
